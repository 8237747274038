import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '@/components/Layout';
import { useParams, useHistory } from 'react-router-dom';
import { isValidParam } from '@/helpers/validators';
import { Spin } from 'antd';
import { SentimentDateFilter } from '@/components/ui/sentiment-date-filter';
import { FiltersProvider, initialState } from '@/components/filter/context';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import useCompany from '@/components/companies/hooks/useCompany';
import { CompanyContainer } from '@/components/companies';

export default function CompanyPage() {
  const { companyId } = useParams();
  const defaultDate = useDefaultDateStore((state) => state.defaultDate);
  const history = useHistory();

  const company = useCompany(companyId, {
    start: defaultDate.start,
    end: defaultDate.end,
  });

  useEffect(() => {
    if (!isValidParam(companyId)) {
      history.push('/404');
    }
  }, []);

  if (company.isLoading) {
    return (
      <Layout disableMantra>
        <Helmet titleTemplate="%s - ClientZen" title="Companies" />

        <div className="mx-auto max-w-[1200px] text-center">
          <Spin size="large" />
        </div>
      </Layout>
    );
  }

  if (company.isError) {
    history.push('/404');
  }

  return (
    <FiltersProvider value={{
      ...initialState,
      filters: {
        ...initialState.filters,
        company_id: companyId,
      },
    }}
    >
      <Layout title={company?.data?.name} disableMantra rightHeader={(<SentimentDateFilter />)}>
        <Helmet titleTemplate="%s - ClientZen" title="Companies" />
        <CompanyContainer />
      </Layout>
    </FiltersProvider>
  );
}

import React from 'react';
import { Helmet } from 'react-helmet';
import AdvancedInsightsContainer from '@/components/advanced-insights/AdvancedInsightsContainer';
import { SentimentDateFilter } from '@/components/ui/sentiment-date-filter';
import Layout from '@/components/Layout';
import { useFiltersStore } from '@/store/zustand/useFiltersStore';
import { FiltersProvider } from '@/components/filter/context';

export default function AdvancedInsightsPage() {
  const { filters } = useFiltersStore();

  const getAppliedFilters = () => {
    const defaultFilters = ['included_keywords', 'sentiment', 'custom_fields', 'excluded_keywords', 'labels', 'sources', 'nps'];
    const appliedFilters = Object.keys(filters).filter((key) => defaultFilters.includes(key) && filters[key].length > 0);

    if (appliedFilters.length === 0) return ['included_keywords', 'sentiment', 'custom_fields'];
    return appliedFilters;
  };

  return (
    <Layout
      rightHeader={(
        <SentimentDateFilter />
      )}
      title="Advanced Insights"
    >
      <Helmet titleTemplate="%s - ClientZen" title="Advanced Insights" />
      <FiltersProvider value={{ filters, selectedFilters: getAppliedFilters() }}>
        <AdvancedInsightsContainer />
      </FiltersProvider>
    </Layout>
  );
}

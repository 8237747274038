/* eslint-disable camelcase */
import { buildQuery } from '@/helpers/filters';
import axios from './axios';
import auth from './auth';

const getTopicGroups = (filters, params) => axios.get(buildQuery(
  '/api/topic-groups', filters, {
    team_id: auth.team().id,
    ...params,
  },
))
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const createTopic = (values) => axios.post(
  '/api/topics',
  {
    follow_exclusions: true,
    ...values,
  }, {
    params: {
      team_id: auth.team().id,
    },
  },
)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const moveToTopic = (values) => axios.post(
  '/api/suggested-topic-groups/move-to-topic',
  values, {
    params: {
      team_id: auth.team().id,
    },
  },
)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const createCategory = (values) => axios.post(
  '/api/topic-groups',
  values, {
    params: {
      team_id: auth.team().id,
    },
  },
)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const getPreview = (values) => axios.post(
  '/api/topics/preview', values, {
    params: {
      team_id: auth.team().id,
    },
  },
)
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const getTopics = (id, filters = {}, params = {}) => axios.get(buildQuery(
  '/api/topics', { group_id: id, ...filters }, {
    team_id: auth.team().id,
    group_id: id,
    ...params,
  },
))
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const getPainpoints = (filters = {}, perPage = 10, page = 1, params = {}) => axios.get(buildQuery(
  '/api/topics', filters, {
    with_annotations: 1,
    team_id: auth.team().id,
    per_page: perPage,
    page,
    ...params,
  },
))
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const getCategories = (id, filters) => axios.get(buildQuery(
  '/api/topics', filters, {
    team_id: auth.team().id,
  },
))
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const getHotTopics = ({ start, end }) => axios.get(buildQuery(
  '/api/topics/hot', {}, {
    team_id: auth.team().id,
    start,
    end,
  },
))
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const getTopicSentiment = ({
  topic_id, subtopic_id, suggested_subtopic_id, client_id, company_id, start, end, customFields, week, month,
}, params) => {
  const url = buildQuery(
    '/api/dashboard-sentiment-count', {}, {
      team_id: auth.team().id,
      start,
      end,
      week,
      month,
      ...(topic_id ? { topic_id } : {}),
      ...(subtopic_id ? { subtopic_id } : {}),
      ...(suggested_subtopic_id ? { suggested_subtopic_id } : {}),
      ...(client_id ? { client_id } : {}),
      ...(company_id ? { company_id } : {}),
      ...params,
    },
  );

  const custom_fields = customFields ? customFields.map(
    (field) => `custom_field_id[]=${encodeURIComponent(field.id)}&custom_field_value[]=${encodeURIComponent(field.value)}`,
  ).join('&') : '';
  return axios.get(`${url}${custom_fields ? `&${custom_fields}` : ''}`).then(({ data }) => data)
    .catch((e) => Promise.reject(e));
};

const getTopic = (id, filters = {}, params = {}) => axios.get(buildQuery(
  `/api/topics/${id}`, filters, {
    team_id: auth.team().id,
    with_annotations: 1,
    ...params,
  },
)).then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const getCounts = (id, filters = {}, params = {}) => axios.get(buildQuery(
  '/api/conversations-counts', filters, {
    team_id: auth.team().id,
    'filter[topic_id]': id,
    ...params,
  },
)).then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const getUsersCount = (id, filters = {}, params = {}) => axios.get(buildQuery(
  '/api/conversations-clients-count', filters, {
    team_id: auth.team().id,
    'filter[topic_id]': id,
    ...params,
  },
)).then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const deleteTopic = (id) => axios.delete(
  `/api/topics/${id}?team_id=${auth.team().id}`,
)
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const deleteCategory = (id) => axios.delete(
  `/api/topic-groups/${id}?team_id=${auth.team().id}`,
)
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const updateTopic = (values) => axios.put(
  `/api/topics/${values.id}`,
  values, {
    params: {
      team_id: auth.team().id,
    },
  },
)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const updateTopicScoreType = (id, type) => axios.put(
  `/api/topics/${id}/score-type`,
  {
    score_type: type,
  }, {
    params: {
      team_id: auth.team().id,
    },
  },
)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const updateAnomalySubscription = (topicId, action) => axios.post(
  '/api/topics/anomaly', {
    topic_id: topicId,
    action,
    team_id: auth.team().id,
  },
)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const addFavoriteTopic = (id) => axios.post(
  `/api/topics/${id}/create-favorite?team_id=${auth.team().id}`,
)
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const updateEvolutionFavorites = (values) => axios.post(
  '/api/topics/create-favorite-evolution', {
    topic_ids: values,
  }, {
    params: {
      team_id: auth.team().id,
    },
  },
).then((res) => res).catch((e) => Promise.reject(e));

const removeFavoriteTopic = (id) => axios.delete(
  `/api/topics/${id}/delete-favorite?team_id=${auth.team().id}`,
)
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const topics = {
  getTopicGroups,
  createTopic,
  moveToTopic,
  deleteTopic,
  updateTopic,
  updateTopicScoreType,
  deleteCategory,
  getTopics,
  getHotTopics,
  getTopic,
  getPreview,
  getTopicSentiment,
  getCategories,
  getPainpoints,
  createCategory,
  updateAnomalySubscription,
  getCounts,
  getUsersCount,
  addFavoriteTopic,
  removeFavoriteTopic,
  updateEvolutionFavorites,
};

export default topics;

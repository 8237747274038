import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import './SummaryResults.less';
import {
  Empty, Spin, message, Button,
} from 'antd';
import { cn } from '@/helpers/util';
import { useFiltersDispatch } from '@/components/filter/context';
import { getSummaryText } from '@/components/summary/SummaryResults';
import SummaryResultItem from './SummaryResultItem';

export default function SummaryResults({
  className, isLoading, error, isError, data, total, perPage = 6, handleSeeMore, seeMoreText, disableClick, threads, selectedId, setSelectedId,
}) {
  const dispatch = useFiltersDispatch();
  const sortedData = useMemo(() => data.sort((a, b) => b.count - a.count), [data]);
  const [currentPage, setCurrentPage] = useState(1);
  const visibleData = useMemo(() => sortedData.slice(0, currentPage * perPage), [sortedData, currentPage, perPage]);
  const showNextPage = useCallback(() => setCurrentPage((current) => current + 1), []);

  useEffect(() => {
    dispatch({ type: 'SET_FILTERS_FIELD', field: 'topic_summary_result_id', value: selectedId });
  }, [selectedId]);

  useEffect(() => {
    if (isError) {
      message.error(error.message, 3);
    }
  }, [error]);

  useEffect(() => {
    setCurrentPage(1);
  }, [sortedData]);

  if (isLoading) {
    return (
      <div className={cn('summary-results', className)}>
        <div className="text-center m-8">
          <Spin />
        </div>
      </div>
    );
  }

  return (
    <div className={cn('summary-results overflow-y-scroll', className)}>
      {!visibleData.length ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No results" />
      ) : visibleData.map((item) => (
        <SummaryResultItem
          key={item.id}
          id={item.id}
          type={item.intent}
          text={threads ? item.summary : getSummaryText(item)}
          mentions={item.count}
          total={total}
          handleSelect={setSelectedId}
          disableClick={disableClick}
          threads={threads}
        />
      ))}
      {
        visibleData.length !== sortedData.length ? (
          <div className="flex m-2 justify-center">
            <Button onClick={handleSeeMore || showNextPage} size="small" type="secondary">{seeMoreText || 'See more'}</Button>
          </div>
        ) : null
      }
    </div>
  );
}

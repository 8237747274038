import React from 'react';
import { cn } from '@/helpers/util';
import {
  MessageCircle, Users, Sigma, DollarSign, Ticket, ArrowUp, ArrowDown,
} from 'lucide-react';
import SentimentGraph from '@/components/bucket/SentimentGraph';
import { useParams } from 'react-router-dom';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import useTopic from '@/components/bucket/hooks/useTopic';
import useSubtopic from '@/components/bucket/hooks/useSubtopic';
import {
  getCsatAvg, getNpsAvg, getSentimentsAvg, mrrFormatter,
} from '@/components/bucket/lib/utils';
import { Spin, Tooltip } from 'antd';
import ScoreChangeMenu from '@/components/bucket/ScoreChangeMenu';
import { useFilters } from '@/components/filter/context';
import { transformCustomFieldToGet } from '@/api/conversations';
import { InfoCircleOutlined } from '@ant-design/icons';
import { hasGorgias, hasIntercom, hasZendesk } from '@/helpers/features';
import { auth } from '@/api';
import useCompany from '@/components/bucket/hooks/useCompany';
import useTopicCounts from '@/components/bucket/hooks/useTopicsCounts';

export const getAvgList = (data) => {
  if (!data) {
    return [];
  }

  switch (data.score_type) {
    case 'sentiment': return getSentimentsAvg(data.sentiment);
    case 'nps': return getNpsAvg(data.nps);
    case 'csat': return getCsatAvg(data.csat);
    default: return [];
  }
};

const getScore = (data) => {
  if (!data) {
    return 0;
  }

  switch (data.score_type) {
    case 'sentiment':
      if (!data.sentiment) {
        return 0;
      }
      return (data.sentiment.positive - data.sentiment.negative) * 100;
    case 'nps':
      if (!data.nps) {
        return 0;
      }
      return (data.nps.promoters - data.nps.detractors) * 100;
    case 'csat':
      if (!data.csat) {
        return 0;
      }
      return data.csat.satisfied * 100;
    default: return 0;
  }
};

export const getSentimentGraphTitle = (type) => {
  switch (type) {
    case 'sentiment': return 'Sentiment score';
    case 'nps': return 'NPS score';
    case 'csat': return 'CSAT score';
    default: return type;
  }
};

export const getSentimentGraphTooltip = (type) => {
  switch (type) {
    case 'sentiment': return 'Ranges from: -100 (completely negative) to 100 (entirely positive)';
    case 'nps': return '(Net Promoter Score) Measures customer loyalty by measuring how likely customers are to recommend your brand, ranging from -100 to 100';
    case 'csat': return 'Measures customer satisfaction on a scale from 0 to 100, with higher scores indicating greater satisfaction';
    default: return type;
  }
};

const OverallScore = ({
  className, payload, isCompany = false, isPeople = false,
}) => {
  const { topicId, subtopicId } = useParams();
  const companyId = payload?.company_id;
  const { defaultDate } = useDefaultDateStore();
  const { filters } = useFilters();
  const { included_keywords: includedKeywords, ...restFilters } = filters;
  const { data: company, refetch: refetchCompany } = useCompany(companyId, {
    start: defaultDate.start,
    end: defaultDate.end,
    keywords: filters.included_keywords,
    sentiment: filters.sentiment,
    labels: filters.labels ? filters.labels.map((label) => {
      if (typeof label === 'object') {
        return label.id;
      }

      return label;
    }) : undefined,
    exclude_labels: filters.exclude_labels,
    excluded_keywords: filters.excluded_keywords,
    sources: JSON.stringify(filters.sources),
    nps: JSON.stringify(filters.nps),
    ...transformCustomFieldToGet(filters.custom_fields),
  }, { enabled: !!companyId });
  const { data: topic, refetch: refetchTopic } = useTopic(topicId, {
    start: defaultDate.start,
    end: defaultDate.end,
    keywords: filters.included_keywords,
    sentiment: filters.sentiment,
    labels: filters.labels ? filters.labels.map((label) => {
      if (typeof label === 'object') {
        return label.id;
      }

      return label;
    }) : undefined,
    exclude_labels: filters.exclude_labels,
    excluded_keywords: filters.excluded_keywords,
    sources: JSON.stringify(filters.sources),
    nps: JSON.stringify(filters.nps),
    ...transformCustomFieldToGet(filters.custom_fields),
  }, { enabled: !!topicId });
  const { data: subtopic, refetch: refetchSubtopic } = useSubtopic(subtopicId, {
    start: defaultDate.start,
    end: defaultDate.end,
    keywords: filters.included_keywords,
    sentiment: filters.sentiment,
    labels: filters.labels ? filters.labels.map((label) => {
      if (typeof label === 'object') {
        return label.id;
      }

      return label;
    }) : undefined,
    exclude_labels: filters.exclude_labels,
    excluded_keywords: filters.excluded_keywords,
    sources: JSON.stringify(filters.sources),
    nps: JSON.stringify(filters.nps),
    ...transformCustomFieldToGet(filters.custom_fields),
  }, { enabled: !!subtopicId });

  const { data: usersCount } = useTopicCounts(topicId, {
    start: defaultDate.start,
    end: defaultDate.end,
    topic_id: topicId,
    subtopic_id: subtopicId,
    ...restFilters,
    keywords: includedKeywords,
    topic_summary_request_id: null,
    follow_exclusions: true,
  }, true);

  const data = (isCompany && company) ? company.data : (subtopic || topic || payload);
  // eslint-disable-next-line no-nested-ternary
  const refetch = isCompany ? refetchCompany : (subtopic ? refetchSubtopic : refetchTopic);
  const avgList = getAvgList(data);
  const evolution = ((data?.sentiment.positive - data?.sentiment.negative) - (data?.sentiment.previous_positive - data?.sentiment.previous_negative)) * 50;
  const user = auth.me();
  const hasTickets = hasZendesk(user) || hasIntercom(user) || hasGorgias(user);
  const ticketsCount = data?.tickets?.value ? data?.tickets.value : 0;
  const peopleCount = data?.clients ? data.clients : 0;

  return (
    <section className={cn('px-5 py-4 font-[Gordita-Regular] text-[#44596C] border-0 border-r border-solid border-[#E5ECF3]', className)}>
      {
        !data ? (
          <div className="w-full h-full z-10 flex justify-center items-center">
            <Spin />
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center">
              <span className="text-sm flex justify-between items-center">
                {getSentimentGraphTitle(data.score_type)}
                <Tooltip title={getSentimentGraphTooltip(data.score_type)} trigger="hover" placement="bottom">
                  <InfoCircleOutlined style={{ margin: '0 10px' }} />
                </Tooltip>
              </span>
              <ScoreChangeMenu topic={topic} data={data} refetch={refetch} />
            </div>
            <div className="flex gap-[8px] ml-4">
              <SentimentGraph
                data={avgList}
                style={{ marginTop: 40 }}
                ratio={data.ratio}
                score={getScore(data)}
                showTrend={false}
                type={data.score_type === 'sentiment'
                  ? (
                    <p className="mt-2 flex items-center justify-center">
                      <span style={{ color: evolution > 0 ? '#5FC59A' : '#F17E99', top: '4px' }}>
                        {evolution > 0 ? <ArrowUp size={16} strokeWidth={2.5} /> : <ArrowDown size={16} strokeWidth={2.5} />}
                      </span>
                      <span>{`${evolution > 0 ? '+' : ''}${evolution.toFixed(0)}%`}</span>
                    </p>
                  )
                  : data.score_type}
              />
              <div style={{ marginTop: 40 }} className="flex flex-col gap-[32px]">
                <div className="table border-spacing-2">
                  {
                      avgList.map((entry) => (
                        <div className="table-row">
                          <div className="table-cell align-middle">
                            <div style={{ backgroundColor: entry.graphColor }} className={cn('w-[8px] h-[8px] rounded-full')} />
                          </div>
                          <div className="table-cell">
                            {entry.name}
                          </div>
                          <div className="table-cell">
                            <div className="text-[#202324] font-[Gordita-Medium]">
                              {entry.value}
                              %
                            </div>
                          </div>
                        </div>
                      ))
                    }
                </div>
                <div className="flex text-[12px]">
                  <div className="table border-spacing-2">
                    <div className="table-row">
                      <div className="table-cell align-middle">
                        <MessageCircle size={12} strokeWidth={1.25} />
                      </div>
                      <div className="table-cell">
                        Feedback
                      </div>
                      <div className="table-cell">
                        <div className="font-[Gordita-Medium]">{data.count.value}</div>
                      </div>
                    </div>
                    {
                      ticketsCount && hasTickets ? (
                        <div className="table-row">
                          <div className="table-cell align-middle">
                            <Ticket size={12} strokeWidth={1.25} />
                          </div>
                          <div className="table-cell">
                            Tickets
                          </div>
                          <div className="table-cell">
                            <div className="font-[Gordita-Medium]">{ticketsCount}</div>
                          </div>
                        </div>
                      ) : null
                    }
                    {
                        data.users ? (
                          <div className="table-row">
                            <div className="table-cell align-middle">
                              <Users size={12} strokeWidth={1.25} />
                            </div>
                            <div className="table-cell">
                              Users
                            </div>
                            <div className="table-cell">
                              <div className="font-[Gordita-Medium]">{data.users.count}</div>
                            </div>
                          </div>
                        ) : null
                      }
                    {
                      (isCompany && peopleCount) || (!isPeople && usersCount) ? (
                        <div className="table-row">
                          <div className="table-cell align-middle">
                            <Users size={12} strokeWidth={1.25} />
                          </div>
                          <div className="table-cell">
                            People
                          </div>
                          <div className="table-cell">
                            <div className="font-[Gordita-Medium]">{isCompany ? peopleCount : usersCount}</div>
                          </div>
                        </div>
                      ) : null
                    }
                    {
                      data.ratio ? (
                        <div className="table-row">
                          <div className="table-cell align-middle">
                            <Sigma size={12} strokeWidth={1.25} />
                          </div>
                          <div className="table-cell">
                            Ratio
                          </div>
                          <div className="table-cell">
                            <div className="font-[Gordita-Medium]">
                              {Math.ceil(data.ratio.value * 100)}
                              %
                            </div>
                          </div>
                        </div>
                      ) : null
                    }
                    {
                      data.mrr && data.mrr.value > 0 ? (
                        <div className="table-row">
                          <div className="table-cell align-middle">
                            <DollarSign size={12} strokeWidth={1.25} />
                          </div>
                          <div className="table-cell">
                            MRR
                          </div>
                          <div className="table-cell">
                            <div className="font-[Gordita-Medium]">{mrrFormatter(data.mrr.value)}</div>
                          </div>
                        </div>
                      ) : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
    </section>
  );
};

export default OverallScore;

/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { getAiVersion } from '@/components/summary/SummaryResults';
import { cn } from '@/helpers/util';
import { useFilters } from '@/components/filter/context';
import { Spin } from 'antd';
import { conversations } from '@/api';
import * as Sentry from '@sentry/react';
import { ReactComponent as IssueIcon } from '../summary/svgs/icon-issue.svg';
import { ReactComponent as QuestionIcon } from '../summary/svgs/icon-question.svg';
import { ReactComponent as RequestIcon } from '../summary/svgs/icon-bulb.svg';
import { ReactComponent as OtherIcon } from '../summary/svgs/icon-other.svg';

export function getResultIcon(type) {
  switch (type) {
    case 'question': return <QuestionIcon />;
    case 'request': return <RequestIcon />;
    case 'other': return <OtherIcon />;
    default: return <IssueIcon />;
  }
}

export default function SummaryResultItem({
  className, id, type, text, mentions, total, handleSelect, disableClick, threads,
}) {
  const { filters } = useFilters();
  const percent = Math.ceil((mentions / total) * 100);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState('');

  useEffect(() => {
    setActive(filters.topic_summary_result_id === id);

    if (filters.topic_summary_result_id === id) {
      setLoading(true);
      conversations.getThreadSummaryResultDetails(id).then(({ data }) => {
        setDetails(data.details);
        setLoading(false);
      }).catch((e) => {
        Sentry.captureException(e);
        setLoading(false);
      });
    }
  }, [filters.topic_summary_result_id]);

  return (
    <>
      <div
        tabIndex={0}
        role="button"
        className={cn('summary-results-item relative group transition-all ease-linear first:mt-1.5', className, active && 'bg-[#F6F9FB]', {
          'hover:bg-[#F6F9FB] cursor-pointer': !disableClick,
          active,
        })}
        onKeyPress={() => (!disableClick ? handleSelect(active ? null : id) : null)}
        onClick={() => (!disableClick ? handleSelect(active ? null : id) : null)}
      >
        <div className={cn('opacity-0 absolute from-[#36B2E9] to-[#1FE04B] bg-gradient-to-b w-[2px] h-full transition-all ease-linear', active && 'opacity-100 duration-100')} />
        <div className={cn('flex rounded-md gap-2.5 px-5 py-2.5', active && '')}>
          <div className="mt-0.5">
            {getResultIcon(type)}
          </div>
          <div className="flex flex-col gap-1">
            <p className="m-0">
              {(getAiVersion() === 'v2' || threads) ? '' : (`${percent}% ${text.split(' ')[0] === 'users' ? 'of' : ''} `)}
              {text}
            </p>
            {
              mentions ? (
                <span className={cn('text-[#75899B] text-[11px] block', active && '')}>
                  {mentions}
                  {' '}
                  tickets
                  {' '}
                  {
                    total ? `• ${percent}% • ` : ' • '
                  }
                  <a className="see-details" onClick={(e) => e.preventDefault()} href="/">
                    {
                      active ? 'Hide details' : 'See details'
                    }
                  </a>
                </span>
              ) : null
            }
          </div>
        </div>
      </div>
      {
        active ? (
          <div className="summary-results-item-details px-6">
            {
              loading ? (
                <div className="px-6">
                  <Spin size="small" />
                </div>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: details }} />
              )
            }
          </div>
        ) : null
      }
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { auth } from '@/api';
import TeamMemberDropdownSimple from '@/components/team/TeamMemberDropdownSimple';
import TeamMemberDropdown from './TeamMemberDropdown';
import LoadingTable from '../LoadingTable';
import Avatar from '../clients/Avatar';
import TeamMemberRoleModal from './TeamMemberRoleModal';
import { DeleteInviteButton } from './DeleteInviteButton';

const { Text } = Typography;

const setModalRolesVisibleAtIndex = (index, modalRolesVisible, setModalRolesVisible) => (value) => {
  const copy = [...modalRolesVisible];
  copy[index] = value;

  setModalRolesVisible(copy);
};

const columns = (loadList, modalRolesVisible, setModalRolesVisible, teamOwner, setTeamOwner, user) => [
  {
    title: 'Avatar',
    dataIndex: 'avatar',
    key: 'avatar',
    width: 46,
    render: (image, client) => <Avatar size={46} client={client} />,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <h4 className="table-text-bold">{text}</h4>,
  },
  {
    title: 'Role',
    key: 'role',
    // eslint-disable-next-line no-nested-ternary
    render: (teamMember, data) => (data.userId ? (((data.role || data.owner) ? (
      <>
        <Text type="secondary">{data.role ? data.role : 'Managing Director'}</Text>
        <span className="font-weight-bold text-black-50">{data.owner ? ' (Administrator)' : ''}</span>
      </>
    ) : (
      <Text type="secondary">{data?.role}</Text>
    ))) : (
      <Text type="secondary">Invite pending</Text>
    )),
    className: 'hide-tablet-v',
  },
  {
    title: 'Actions',
    width: 100,
    render: (teamMember, data, index) => (data.userId ? (
      <>
        <TeamMemberRoleModal
          loadList={loadList}
          member={teamMember}
          visible={modalRolesVisible[index]}
          setVisible={setModalRolesVisibleAtIndex(index, modalRolesVisible, setModalRolesVisible)}
        />
        { teamOwner
        && (
        <TeamMemberDropdown
          loadList={loadList}
          teamMember={teamMember}
          changeRole={setModalRolesVisibleAtIndex(index, modalRolesVisible, setModalRolesVisible)}
          isOwner={data.owner}
          setTeamOwner={setTeamOwner}
        />
        )}
        {
          (!teamOwner && user.has_delete_team_members) && (
            <TeamMemberDropdownSimple
              loadList={loadList}
              teamMember={teamMember}
              changeRole={setModalRolesVisibleAtIndex(index, modalRolesVisible, setModalRolesVisible)}
              isOwner={data.owner}
              setTeamOwner={setTeamOwner}
            />
          )
        }
      </>
    ) : (
      <>
        <DeleteInviteButton loadList={loadList} member={teamMember} />
      </>
    )),
  },
];

const TeamMemberTable = ({
  teamMembers, loadList, user, ...props
}) => {
  const [modalRolesVisible, setModalRolesVisible] = useState(teamMembers.map(() => false));
  const [teamOwner, setTeamOwner] = useState(false);
  useEffect(() => {
    const fetchTeamOwner = async () => {
      const team = await auth.team();
      setTeamOwner(team.owner);
    };

    fetchTeamOwner();
  }, []);

  return (
    <LoadingTable
      showHeader={false}
      columns={columns(loadList, modalRolesVisible, setModalRolesVisible, teamOwner, setTeamOwner, user)}
      dataSource={teamMembers}
      {...props}
    />
  );
};

export default TeamMemberTable;

import { useMemo } from 'react';
import useURLState from '@/hooks/useUrlState';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';

const useCompanyFilters = () => {
  // Range filters

  const { defaultDate } = useDefaultDateStore();
  const { urlQueryState, setUrlQueryParams } = useURLState({
    conversations: undefined,
    topics: undefined,
    mrr: undefined,
    search: '',
    name: defaultDate.name,
    renewal: undefined,
    userType: undefined,
    sentiment: undefined,
  });

  const filters = useMemo(
    () => ({
      name: urlQueryState.search ? urlQueryState.search : '',
      start: defaultDate.start,
      end: defaultDate.end,
      renewal: urlQueryState.renewal,
      userType: urlQueryState.userType,
      sentiment: urlQueryState.sentiment ? urlQueryState.sentiment : undefined,
    }),
    [urlQueryState],
  );

  return {
    sorts: urlQueryState,
    setSort: (name, value) => {
      setUrlQueryParams({
        conversations: undefined,
        clients: undefined,
        tickets: undefined,
        topics: undefined,
        mrr: undefined,
        page: 1,
        [name]: value,
      });
    },
    search: urlQueryState.search,
    setSearch: (value) => {
      setUrlQueryParams({
        page: 1,
        search: value,
      });
    },
    rangeFilter: defaultDate,
    setRangeFilter: (values) => setUrlQueryParams({
      page: 1,
      ...values,
    }),
    tableFilters: {
      renewal: urlQueryState.renewal,
      userType: urlQueryState.userType,
      sentiment: urlQueryState.sentiment ? urlQueryState.sentiment : undefined,
    },
    setTableFilter: (name, value) => {
      setUrlQueryParams({
        page: 1,
        [name]: value,
      });
    },
    filters,
  };
};

export default useCompanyFilters;

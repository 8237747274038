import React, { useEffect, useState } from 'react';
import {
  Button, Empty, Modal, Spin,
} from 'antd';
import { withRouter } from 'react-router-dom';
import moment from 'moment/moment';
import { conversations } from '@/api';
import ConversationsIcon from '@/components/svgs/icons/ConversationsIcon';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ConversationsTable from '@/base-components/conversations-table';
import { improveLabel } from '@/components/filter/filters/CustomFieldFilter';
import { getAiVersion } from '@/components/summary/SummaryResults';
import { ReactComponent as GenerateSummaryIcon } from '../../svgs/icon-summary.svg';
import { ReactComponent as BetaIcon } from '../../svgs/beta.svg';
import mixpanel, { mixpanelEvents } from '../../../mixpanel';
import { showDateLabel, showLabel } from './SelectedCustomField';

const prepareCustomFields = (customFields) => customFields.map(({ id, value }) => ({ id, value }));

const getSummaryText = (item) => {
  if (getAiVersion() === 'v2') {
    return item.summary;
  }

  const principal = 'users';
  switch (item.intent) {
    case 'issue': return item.summary.indexOf('facing') !== -1 ? item.summary : `${principal} are facing ${item.summary}`;
    case 'question': return item.summary.indexOf('ask') !== -1 ? item.summary : `${principal} are asking about the ${item.summary}`;
    case 'request': return item.summary.indexOf('request') !== -1 ? item.summary : `${principal} are requesting information about the ${item.summary}`;
    default: return item.summary;
  }
};

const SummaryButton = ({
  name,
  id,
  location: { pathname },
  start,
  end,
  customFields = [],
  sentiment = [],
  keywords = [],
  subtopic = null,
  company = null,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [elements, setElements] = useState([]);
  const [copied, setCopied] = useState(false);
  const [summaryResult, setSummaryResult] = useState(null);

  const isDemo = pathname.startsWith('/demo');

  const generateSummary = () => {
    let intervalId = null;

    setLoading(true);
    conversations
      .getSummary(
        id,
        company,
        start,
        end,
        prepareCustomFields(customFields),
        subtopic,
        keywords,
        sentiment,
      )
      .then((response) => {
        conversations
          .getSummaryResult(response.data.data.id)
          .then((firstResult) => {
            clearInterval(intervalId);

            if (firstResult.data.status === 'completed' && !firstResult.data.results.length) {
              setLoading(false);
              setElements([]);
            } else if (firstResult.data.results.length) {
              setElements(firstResult.data.results.sort((a, b) => b.count - a.count).slice(0, 5));
              setLoading(false);
            } else {
              intervalId = setInterval(() => {
                conversations
                  .getSummaryResult(response.data.data.id)
                  .then((result) => {
                    if (result.data.status === 'completed' && !result.data.results.length) {
                      clearInterval(intervalId);
                      setElements([]);
                      setLoading(false);
                    } else if (result.data.results.length) {
                      clearInterval(intervalId);
                      setElements(result.data.results.sort((a, b) => b.count - a.count).slice(0, 5));
                      setLoading(false);
                    }
                  })
                  .catch(() => {});
              }, 4000);
            }
          })
          .catch(() => {});
      })
      .catch(() => {});

    return () => clearInterval(intervalId);
  };

  useEffect(() => {
    let intervalClear;

    if (open) {
      intervalClear = generateSummary();
    }

    return intervalClear;
  }, [open]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  return (
    <>
      {!isDemo ? (
        <Button
          onClick={() => {
            mixpanel.track(mixpanelEvents.GENERATE_SUMMARY_CLICKED);
            setOpen(true);
          }}
        >
          <span className="anticon">
            <GenerateSummaryIcon />
          </span>
          {' '}
          Generate summary
        </Button>
      ) : null}
      <Modal
        className="modal-summary"
        title={summaryResult ? (
          <div className="individual-summary-result-header">
            <Button type="link" onClick={() => setSummaryResult(null)}><ArrowLeftOutlined /></Button>
            <div>
              <div>
                {getSummaryText(summaryResult)}
              </div>
              <div className="summary-conversation-count">
                {summaryResult.count}
                {' '}
                Conversations
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div>
              {`Summary for ${name}`}
              <span className="anticon" style={{ marginLeft: 10 }}>
                <BetaIcon />
              </span>
            </div>
            <div
              style={{
                fontSize: 14,
                fontFamily: 'Gordita-Regular, sans-serif',
                marginTop: 16,
              }}
            >
              <div>
                <span>Interval analyzed: </span>
                <span style={{ fontFamily: 'Gordita-Medium, sans-serif' }}>
                  {moment(start, 'YYYY-MM-DD').format('D MMM YYYY')}
                  {' '}
                  -
                  {' '}
                  {moment(end, 'YYYY-MM-DD').format('D MMM YYYY')}
                </span>
              </div>

              {customFields.length > 0 ? (
                <div style={{ marginTop: '8px' }}>
                  <span>Filter: </span>
                  <span style={{ fontFamily: 'Gordita-Medium, sans-serif' }}>
                    {customFields.map((field, index) => (
                      <span key={field.name}>
                        {`${field.name}: ${
                          field.dataType === 'date'
                            ? showDateLabel(field.value)
                            : improveLabel(showLabel(field.value))
                        } ${customFields.length !== index + 1 ? '/' : ''} `}
                      </span>
                    ))}
                  </span>
                </div>
              ) : null}

              {keywords.length > 0 ? (
                <div style={{ marginTop: '8px' }}>
                  <span>Keywords: </span>
                  <span style={{ fontFamily: 'Gordita-Medium, sans-serif' }}>
                    {keywords.map((key, index) => (
                      <span key={key}>
                        {`${key} ${keywords.length !== index + 1 ? '/' : ''} `}
                      </span>
                    ))}
                  </span>
                </div>
              ) : null}
              {sentiment.length > 0 ? (
                <div style={{ marginTop: '8px' }}>
                  <span>Sentiment: </span>
                  <span style={{ fontFamily: 'Gordita-Medium, sans-serif' }}>
                    {
                      sentiment.map((key, index) => (
                        <span key={key}>
                          {`${key[0].toUpperCase() + key.slice(1)} ${sentiment.length !== index + 1 ? '/' : ''} `}
                        </span>
                      ))
                    }
                  </span>
                </div>
              ) : null}
              {subtopic !== null ? (
                <div style={{ marginTop: '8px' }}>
                  <span>Subtopic: </span>
                  <span style={{ fontFamily: 'Gordita-Medium, sans-serif' }}>
                    {subtopic.name}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        )}
        visible={open}
        width={800}
        onCancel={() => {
          setOpen(false);
          setSummaryResult(null);
        }}
        footer={[
          summaryResult ? null : (
            <Button
              style={{ width: 160 }}
              onClick={() => {
                navigator.clipboard.writeText(
                  elements.reduce((val, item) => `${val}\n${item.summary}`, ''),
                );
                setCopied(true);
              }}
            >
              {copied ? 'Copied' : 'Copy to clipboard'}
            </Button>
          ),
          <Button
            type="primary"
            onClick={() => {
              setOpen(false);
              setSummaryResult(null);
            }}
          >
            Done
          </Button>,
        ]}
      >
        {
          summaryResult ? (
            <div className="individual-summary-result">
              <ConversationsTable
                filters={{ topic_summary_result_id: summaryResult.id }}
                showTopicName={false}
                hasPagination
                showSource={false}
                showKeywords={false}
                disableUrlQueryState
              />
            </div>
          ) : null
        }
        {loading ? (
          <div style={{ textAlign: 'center', padding: 24 }}>
            <Spin size="large" />
          </div>
        ) : null}
        {!loading && elements.length && !summaryResult ? (
          <div className="ul-summary">
            {elements.map((el) => (
              <div className="element-summary">
                <ul>
                  <li key={el.id}>{getSummaryText(el)}</li>
                </ul>
                <Button
                  icon={
                    <span className="anticon count-anticon"><ConversationsIcon /></span>
                  }
                  className="count-button"
                  type="secondary"
                  onClick={() => setSummaryResult(el)}
                >
                  {el.count}
                </Button>
              </div>
            ))}
          </div>
        ) : null}
        {!loading && !elements.length && !summaryResult ? (
          <div style={{ textAlign: 'center' }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default withRouter(SummaryButton);

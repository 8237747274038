import { buildQuery } from '@/helpers/filters';
import axios from './axios';
import auth from './auth';

const index = () => axios.get('/api/companies', {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response).catch((e) => Promise.reject(e));

const indexList = (filters, page, sort, perPage = 15, searchValue = '') => axios.get(buildQuery('/api/companies-list', filters, {
  ...(page ? { page } : undefined),
  ...(sort ? { sort } : undefined),
  team_id: auth.team().id,
  per_page: perPage,
  search_value: searchValue,
})).then((response) => response).catch((e) => Promise.reject(e));

const show = (id, filters, params) => axios.get(buildQuery(`/api/companies/${id}`, filters, {
  team_id: auth.team().id,
  ...params,
})).then((response) => response).catch((e) => Promise.reject(e));

const updateCompanyScoreType = (id, type) => axios.put(
  `/api/companies/${id}/score-type`,
  {
    score_type: type,
  }, {
    params: {
      team_id: auth.team().id,
    },
  },
)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const companies = {
  index,
  indexList,
  show,
  updateCompanyScoreType,
};

export default companies;

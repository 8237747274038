/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Affix, Layout as AntdLayout } from 'antd';
import { connect } from 'react-redux';
import { cn } from '@/helpers/util';
import ChainlitInput from '@/components/chainlit';
import { useResponsiveSiderStore } from '@/store/zustand/useResponsiveSiderStore';
import AskMantraButton from '@/components/bucket/AskMantraButton';
import ResponsiveSider from './ResponsiveSider';
import Breadcrumb from './ui/breadcrumb';

const { Header, Content } = AntdLayout;

const Layout = ({
  children,
  breadcrumb,
  title,
  disableMantra = false,
  leftHeader,
  rightHeader,
  rightHeaderClasses,
  pageWithoutSider,
  ...rest
}) => {
  const collapsed = useResponsiveSiderStore((state) => state.collapsed);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const location = useLocation();
  const isTeamMemberPage = location.pathname.startsWith('/start') || location.pathname.includes('team-members');

  return (
    <AntdLayout style={{ minHeight: '100vh' }} {...rest}>
      {!pageWithoutSider && (
        <ResponsiveSider drawerVisible={drawerVisible} setDrawerVisible={setDrawerVisible} />
      )}
      <AntdLayout className={cn('ml-[80px]', !collapsed && 'ml-[280px]')}>
        <Affix className="z-20">
          <Header
            theme="light"
            className={cn('cz-header', !breadcrumb && '!items-center')}
          >
            <div className="flex items-baseline space-x-4">
              {breadcrumb || <Breadcrumb title={title} />}
              {
                !disableMantra && (location.pathname.indexOf('/topic') === -1 && location.pathname.indexOf('/discovery') === -1 && location.pathname.indexOf('/advanced-insights') === -1) ? (
                  <AskMantraButton className="in-header" />
                ) : null
              }
              {leftHeader || null}
            </div>
            {rightHeader ? (
              <div className={cn('flex items-baseline space-x-4', rightHeaderClasses)}>
                {rightHeader}
              </div>
            ) : null}
          </Header>
        </Affix>
        <Content>
          <div className={cn('h-full pb-6', isTeamMemberPage && 'team-side')}>
            {children}
          </div>
        </Content>
      </AntdLayout>
    </AntdLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  subscription: state.subscription,
});

export default connect(mapStateToProps, null)(Layout);

/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { Menu as AntdMenu } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  LayersIcon, LayoutGridIcon, MessageCircleIcon, TagIcon, UsersIcon, Plug2Icon, SparkleIcon,
} from 'lucide-react';
import TrackedLink from './TrackedLink';
import { mixpanelEvents } from '../mixpanel';
import ProfileMenu from './ProfileMenu';
import { Icons } from './ui/icons';

const menuItems = [
  {
    key: 'dashboard',
    icon: <LayoutGridIcon size={18} strokeWidth={2} />,
    link: '/',
    event: mixpanelEvents.MENU_HOME_CLICKED,
    name: 'Dashboard',
  },
  {
    key: 'discovery',
    icon: <MessageCircleIcon size={18} strokeWidth={2} />,
    link: '/discovery',
    event: mixpanelEvents.MENU_DISCOVERY_CLICKED,
    name: 'Discovery',
  },
  {
    key: 'advanced-insights',
    icon: <MessageCircleIcon size={18} strokeWidth={2} />,
    link: '/advanced-insights',
    event: mixpanelEvents.MENU_ADVANCED_INSIGHTS_CLICKED,
    name: 'Advanced Insights',
    hiddenForUsers: true,
  },
  {
    key: 'taxonomy',
    icon: <Icons.Taxonomy />,
    link: '/taxonomy',
    event: mixpanelEvents.MENU_CATEGORIES_CLICKED,
    name: 'Taxonomy',
  },
  {
    key: 'people',
    icon: <UsersIcon size={18} strokeWidth={2} />,
    link: '/people',
    event: mixpanelEvents.MENU_PEOPLE_CLICKED,
    name: 'People',
  },
  {
    key: 'divider',
  },
  {
    key: 'integrations',
    icon: <Plug2Icon size={18} strokeWidth={2} />,
    link: '/integrations',
    event: mixpanelEvents.MENU_PLATFORMS_CLICKED,
    name: 'Sources',
  },
];

const Menu = ({
  location: { pathname }, collapsed, user,
}) => {
  const selectedKeys = useMemo(() => [
    pathname.split('/')[pathname.startsWith('/demo') ? 2 : 1] || 'dashboard',
  ], [pathname]);
  const isDemo = pathname.startsWith('/demo');

  if (isDemo) {
    return (
      <AntdMenu selectedKeys={selectedKeys} mode="inline">
        <AntdMenu.Item key="home" icon={<LayoutGridIcon size={18} strokeWidth={2} />}>
          <TrackedLink to="/demo" event={mixpanelEvents.MENU_HOME_CLICKED}>Home</TrackedLink>
        </AntdMenu.Item>
        <AntdMenu.Item key="topics" icon={<LayersIcon size={18} strokeWidth={2} />}>
          <TrackedLink to="/demo/topics" event={mixpanelEvents.MENU_PAINPOINTS_CLICKED}>Segments</TrackedLink>
        </AntdMenu.Item>
        <AntdMenu.Item key="discovery" icon={<SparkleIcon size={18} strokeWidth={2} />}>
          <TrackedLink to="/demo/discovery" event={mixpanelEvents.MENU_DISCOVERY_CLICKED}>Discovery</TrackedLink>
        </AntdMenu.Item>
        <AntdMenu.Item key="categories" icon={(<TagIcon size={18} strokeWidth={2} />)}>
          <TrackedLink to="/demo/categories" event={mixpanelEvents.MENU_CATEGORIES_CLICKED}>Categories</TrackedLink>
        </AntdMenu.Item>
        <AntdMenu.Item key="conversations" icon={<MessageCircleIcon size={18} strokeWidth={2} />}>
          <TrackedLink to="/demo/conversations" event={mixpanelEvents.MENU_CONVERSATIONS_CLICKED}>Conversations</TrackedLink>
        </AntdMenu.Item>
        <AntdMenu.Item key="people" icon={<UsersIcon size={18} strokeWidth={2} />}>
          <TrackedLink to="/demo/people" event={mixpanelEvents.MENU_PEOPLE_CLICKED}>People</TrackedLink>
        </AntdMenu.Item>
      </AntdMenu>
    );
  }

  return (
    <AntdMenu selectedKeys={selectedKeys} mode="inline">
      {menuItems.map((item) => ((item.hiddenForUsers && !user.email.endsWith('@clientzen.io')) ? null : ((item.key !== 'divider' ? (
        <AntdMenu.Item key={item.key} icon={item.icon} className="!p-3">
          <TrackedLink to={item.link} event={item.event}>{item.name}</TrackedLink>
        </AntdMenu.Item>
      ) : (
        <AntdMenu.Divider key="divider" className="navigation-space-between" />
      )))))}
      <ProfileMenu collapsed={collapsed} />
    </AntdMenu>
  );
};

const mapStateToProps = (state) => ({
  subscription: state.subscription,
  user: state.user,
});

export default connect(mapStateToProps, null)(withRouter(Menu));

import React, { useEffect, useState } from 'react';
import PeopleTable from '@/base-components/people-table';
import { Helmet } from 'react-helmet';
import { useFiltersStore } from '@/store/zustand/useFiltersStore';
import AddFilterButton from '@/components/bucket/AddFilterButton';
import { Button, Divider } from 'antd';
import FiltersHorizontalList from '@/components/bucket/FiltersHorizontalList';
import SegmentDropdown from '@/components/people/components/CreateSegment/SegmentDropdown';
import ExportButton from '@/components/ui/export-button';
import { isEqual } from 'lodash';
import CompaniesTable from '@/base-components/people-table/components/CompaniesTable';
import useCompanyFilters from '../companies/useFilters';
import useFilters from './useFilters';
import SearchInput from './components/SearchInput';
import Layout from '../Layout';
import { SentimentDateFilter } from '../ui/sentiment-date-filter';
import { FiltersProvider, useFilters as useFiltersContext } from '../filter/context';

const PeopleBody = ({ searchValue, activeTab, setActiveTab }) => {
  const {
    sorts, setSort,
  } = useFilters();

  const { filters, setFilters } = useFiltersStore();
  const { filters: lFilters } = useFiltersContext();

  useEffect(() => {
    if (!isEqual(lFilters, filters)) setFilters(lFilters);
  }, [lFilters]);

  return (
    <section className="people mx-7 2xl:mx-auto 2xl:max-w-[1200px]">
      <div className="people__body">
        <div className="flex bucket-top-row !pl-0">
          <Button
            onClick={() => setActiveTab('people')}
            className={`ant-btn ant-btn-secondary px-4 py-2 ${
              activeTab === 'people' ? 'active-tab' : ''
            }`}
            style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
          >
            People
          </Button>
          <Button
            onClick={() => setActiveTab('companies')}
            className={`ant-btn ant-btn-secondary px-4 py-2 ${
              activeTab === 'companies' ? 'active-tab' : ''
            }`}
            style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
          >
            Companies
          </Button>
          <Divider type="vertical" />
          <AddFilterButton />
          <Divider type="vertical" />
          <SegmentDropdown />
        </div>
        <FiltersHorizontalList showSaveButton={false} />
        <PeopleTable
          perPage={10}
          setSort={setSort}
          sorts={sorts}
          searchValue={searchValue}
        />
      </div>
    </section>
  );
};

const CompaniesBody = ({ searchValue, activeTab, setActiveTab }) => {
  const {
    sorts, setSort,
  } = useCompanyFilters();

  const { filters, setFilters } = useFiltersStore();
  const { filters: lFilters } = useFiltersContext();

  useEffect(() => {
    if (!isEqual(lFilters, filters)) setFilters(lFilters);
  }, [lFilters]);

  return (
    <section className="people mx-7 2xl:mx-auto 2xl:max-w-[1200px]">
      <div className="people__body">
        <div className="flex bucket-top-row !pl-0 items-center">
          <Button
            onClick={() => setActiveTab('people')}
            className={`ant-btn ant-btn-secondary px-4 py-2 ${
              activeTab === 'people' ? 'active-tab' : ''
            }`}
            style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
          >
            People
          </Button>
          <Button
            onClick={() => setActiveTab('companies')}
            className={`ant-btn ant-btn-secondary px-4 py-2 ${
              activeTab === 'companies' ? 'active-tab' : ''
            }`}
            style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
          >
            Companies
          </Button>
          <Divider type="vertical" />
          <AddFilterButton />
          <Divider type="vertical" />
          <SegmentDropdown />
        </div>
        <FiltersHorizontalList showSaveButton={false} />
        <CompaniesTable
          perPage={10}
          setSort={setSort}
          sorts={sorts}
          searchValue={searchValue}
        />
      </div>
    </section>
  );
};

const People = () => {
  const { filters } = useFiltersStore();
  const [search, setSearch] = useState('');
  const [activeTab, setActiveTab] = useState('people');
  const getAppliedFilters = () => {
    const defaultFilters = ['included_keywords', 'sentiment', 'custom_fields', 'excluded_keywords', 'labels', 'sources', 'topic', 'segments', 'nps'];
    const appliedFilters = Object.keys(filters).filter((key) => defaultFilters.includes(key) && filters[key].length > 0);

    if (appliedFilters.length === 0) return ['included_keywords', 'sentiment', 'custom_fields'];
    return appliedFilters;
  };

  return (
    <Layout
      leftHeader={(
        <>
          <SearchInput search={filters.name} setSearch={setSearch} />
        </>
      )}
      rightHeader={(
        <>
          <SentimentDateFilter />
          <ExportButton filename="clientzen-people-export" type="people" searchValue={search} />
        </>
      )}
    >
      <Helmet titleTemplate="People - ClientZen" title="Discovery" />
      <FiltersProvider value={{ filters, selectedFilters: getAppliedFilters() }}>
        { activeTab === 'people' ? (
          <PeopleBody searchValue={search} activeTab={activeTab} setActiveTab={setActiveTab} />
        ) : (
          <CompaniesBody searchValue={search} activeTab={activeTab} setActiveTab={setActiveTab} />
        )}
      </FiltersProvider>
    </Layout>
  );
};

export default People;

import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { ChevronUp, ChevronDown } from 'lucide-react';
import { companies, dashboard, topics } from '@/api';
import './ScoreChangeMenu.styles.less';
import { displayOrCatchMessage } from '@/helpers';
import { connect } from 'react-redux';

export const getLabel = (selected) => {
  switch (selected) {
    case 'sentiment': return 'Sentiment';
    case 'nps': return 'NPS';
    case 'csat': return 'CSAT';
    default: return selected;
  }
};

const ScoreChangeMenu = ({
  topic, data, refetch, user, onDashboard = false, isCompany = false,
}) => {
  const [selected, setSelected] = useState(onDashboard ? data.global_score_type : data.score_type);
  const [opened, setOpened] = useState(false);
  const selectScoreType = useCallback((type) => {
    if (topic) {
      setSelected(type);
      topics.updateTopicScoreType(topic.id, type).then(() => {
        if (refetch) {
          refetch();
        }
      }).catch((e) => {
        displayOrCatchMessage(e);
      });
    }
    if (onDashboard) {
      dashboard.updateGlobalScoreType(type).then(() => {
        if (refetch) {
          refetch();
        }
      }).catch((e) => {
        displayOrCatchMessage(e);
      });
    }
    if (isCompany) {
      companies.updateCompanyScoreType(data.company_id, type).then(() => {
        if (refetch) {
          refetch();
        }
      }).catch((e) => {
        displayOrCatchMessage(e);
      });
    }
  }, [topic?.id, refetch]);

  useEffect(() => {
    if (topic || isCompany) {
      setSelected(data.score_type);
    }
    if (onDashboard) {
      setSelected(data.global_score_type);
    }
  }, [data?.score_type, data?.global_score_type]);

  if (!user.has_nps && !user.has_csat) {
    return <span />;
  }

  const overlay = (
    <Menu>
      <Menu.Item onClick={() => selectScoreType('sentiment')}>Sentiment</Menu.Item>
      {
        user.has_nps ? (
          <Menu.Item onClick={() => selectScoreType('nps')}>NPS</Menu.Item>
        ) : null
      }
      {
        user.has_csat ? (
          <Menu.Item onClick={() => selectScoreType('csat')}>CSAT</Menu.Item>
        ) : null
      }
    </Menu>
  );

  return (
    <Dropdown onVisibleChange={setOpened} overlay={overlay} trigger="click">
      <Button type="link" className="score-change-button flex gap-1">
        <span>{getLabel(selected)}</span>
        <span>{opened ? <ChevronUp size={10} /> : <ChevronDown size={10} />}</span>
      </Button>
    </Dropdown>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(ScoreChangeMenu);

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import debounce from 'lodash/debounce';
import {
  UserAddOutlined, ShopOutlined,
} from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import Layout from '@/components/Layout';
import TeamMemberTable from '@/components/team/TeamMemberTable';
import { members } from '@/api';
import { displayOrCatchMessage } from '@/helpers';
import TeamMemberAddModal from '@/components/team/TeamMemberAddModal';
import {
  getMemberData,
} from '@/transformers/members';
import config from '@/config';
import Affix from '@/components/Affix';
import { connect } from 'react-redux';

export const load = (setLoadedTeamMembers, setLoading, setPager) => (page) => {
  setLoading(true);
  members.index({}, page).then((response) => {
    setLoadedTeamMembers(response.data.data.map(getMemberData));
    setPager(response.data.pager);
    setLoading(false);
  }).catch((e) => {
    displayOrCatchMessage(e);
    setLoading(false);
  });
};

const onBeginAdd = (setIsAddVisible) => () => {
  setIsAddVisible(true);
};

const TeamMembers = ({ user }) => {
  const [loadedTeamMembers, setLoadedTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pager, setPager] = useState(null);
  const loadTeamMemberList = debounce(load(setLoadedTeamMembers, setLoading, setPager), 300);
  const [isAddVisible, setIsAddVisible] = useState(false);

  useEffect(() => {
    loadTeamMemberList(1);
  }, []);

  const handleChange = (pagination, filters, sorter, { currentDataSource, action }) => {
    if (action === 'paginate') {
      setLoading(true);
      loadTeamMemberList(pagination.current);
    }
  };

  return (
    <Layout
      title="Team"
      rightHeader={(
        <Button type="secondary" onClick={onBeginAdd(setIsAddVisible)} className="btn-only-icon-mobile">
          <UserAddOutlined />
          {' '}
          Invite Team Member
        </Button>
    )}
    >
      <Helmet>
        <title>ClientZen - My Team</title>
      </Helmet>
      <TeamMemberAddModal
        loadList={loadTeamMemberList}
        setVisible={setIsAddVisible}
        visible={isAddVisible}
      />
      <div className="mx-6 2xl:mx-auto 2xl:max-w-[1200px]">
        <TeamMemberTable
          loadList={loadTeamMemberList}
          teamMembers={loadedTeamMembers}
          loading={loading}
          onChange={handleChange}
          user={user}
          pagination={{
            pageSize: config.pageSize,
            total: pager?.total ?? 0,
            current: pager?.current_page ?? 1,
            showSizeChanger: false,
          }}
        />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(TeamMembers);

import React, { useState } from 'react';
import { Dropdown, Menu, Modal } from 'antd';
import { Redirect } from 'react-router-dom';
import { members } from '@/api';
import {
  MoreVerticalIcon, Trash2Icon,
} from 'lucide-react';
import { displayOrCatchMessage } from '../../helpers';

const onBeginArchive = (loadList, setRedirect, teamMember) => () => members.destroy(teamMember)
  .then(() => {
    loadList();
    setRedirect(true);
  }).catch((e) => {
    displayOrCatchMessage(e);
  });

const onArchive = (loadList, setRedirect, teamMember) => () => {
  Modal.confirm({
    title: `Are you sure you want to remove team member ${teamMember.name}?`,
    content: '',
    okText: 'Remove',
    cancelText: 'Cancel',
    okType: 'primary',
    cancelType: 'default',
    onOk: onBeginArchive(loadList, setRedirect, teamMember),
    okButtonProps: {
      danger: true,
    },
  });
};

const TeamMemberDropdownSimple = ({
  loadList, teamMember,
}) => {
  const [redirect, setRedirect] = useState(false);
  return (
    <>
      {
        redirect ? (
          <Redirect push to="/users" />
        ) : null
      }
      <div className="flex items-center float-right">
        <Dropdown
          className="cursor-pointer"
          placement="bottomRight"
          trigger={['click']}
          overlay={(
            <Menu
              className="!rounded-md shadow-[0px_1px_12px_#CAD7E180] border border-solid border-[#DFE9F1] font-[Gordita-Regular]"
            >
              <Menu.Item
                className="flex items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB]"
                onClick={onArchive(loadList, setRedirect, teamMember)}
              >
                <Trash2Icon size={16} strokeWidth={2} />
                <span>Remove team member</span>
              </Menu.Item>
            </Menu>
          )}
        >
          <MoreVerticalIcon className="hover:stroke-[#44596C]" color="#8F9DA9" size={20} />
        </Dropdown>
      </div>
    </>
  );
};
export default TeamMemberDropdownSimple;

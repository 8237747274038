import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import BucketGraph from '@/components/bucket/BucketGraph';
import { useFilters } from '@/components/filter/context';
import SummaryContainer from '@/components/bucket/SummaryContainer';
import OverallScore from '../bucket/OverallScore';
import useCompany from './hooks/useCompany';
import { FeedbackContainer } from '../discovery/DiscoveryContainer';

export function CompanyContainer() {
  const { companyId } = useParams();
  const defaultDate = useDefaultDateStore((state) => state.defaultDate);

  const { data } = useCompany(companyId, {
    start: defaultDate.start,
    end: defaultDate.end,
  });

  const { filters } = useFilters();

  const parentRef = useRef(null);
  const [headerWidth, setHeaderWidth] = React.useState(0);
  const [headerHeight, setHeaderHeight] = React.useState(0);
  const ticketsCount = data?.tickets?.value ? data?.tickets.value : 0;
  const usersCount = data?.clients ? data.clients : 0;
  const feedbackCount = data?.count?.value ? data.count.value : 0;

  useEffect(() => {
    if (parentRef && parentRef.current) {
      setHeaderWidth(parentRef.current.offsetWidth);
      setHeaderHeight(parentRef.current.offsetHeight);
      const resizeObserver = new ResizeObserver((entries) => {
        setHeaderWidth(entries[0].target.offsetWidth);
        setHeaderHeight(entries[0].target.offsetHeight);
      });
      resizeObserver.observe(parentRef.current);
    }
  }, []);

  return (
    <main ref={parentRef} className="flex flex-col bg-white border-0 border-t border-solid border-[rgb(229,236,243)]">
      <div className="flex min-h-[320px]">
        <OverallScore
          payload={{
            ...data,
            score_type: 'sentiment',
          }}
          className="min-w-[410px]"
          isCompany
        />
        <BucketGraph
          lFilters={{
            company_id: filters.company_id,
          }}
        />
      </div>
      <div
        className="flex min-h-[380px] border-0 border-t border-solid border-[#E5ECF3] font-[Gordita-Regular] text-[#44596C]"
        style={{ width: headerWidth, height: `calc(100vh - 72px - ${headerHeight}px)` }}
      >
        <div className="flex flex-col flex-1 border-0 border-r border-solid border-[#E5ECF3]">
          <FeedbackContainer className="grow pb-11 rounded-none" usersCount={usersCount} ticketsCount={ticketsCount} feedbackCount={feedbackCount} headerPagination followExclusions={false} />
        </div>
        <div className="flex flex-col flex-1 border-0 border-l border-solid border-[#E5ECF3]">
          <SummaryContainer
            headerText="Summary"
            headerClassName="text-sm pt-4 pb-2 pl-5"
          />
        </div>
      </div>
    </main>
  );
}

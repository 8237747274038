import { companies } from '@/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export default function useCompany(id, filters, options) {
  return useQuery({
    queryKey: ['company', id, { filters }],
    queryFn: async () => {
      const { data } = await companies.show(id, filters);
      return data;
    },
    refetchOnWindowFocus: !!filters,
    ...options,
  });
}

export async function prefetchTopic(id, filters) {
  const queryClient = useQueryClient();
  await queryClient.prefetchQuery(['topic', id, { filters }], async () => {
    const { data } = await companies.show(id, filters);
    return data;
  });
}

import { conversations } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { transformCustomFieldToPost } from '@/api/conversations';

const sleep = (ms) => new Promise((resolve) => {
  setTimeout(() => resolve(), ms);
});

const getSummaryResult = async (summary, threads = false) => {
  const api = threads ? conversations.getThreadSummaryResult : conversations.getSummaryResult;
  const results = await api(summary.data.data.id);

  if (results.data.status === 'completed') {
    const { data } = results;
    return { ...data, id: summary.data.data.id };
  }

  await sleep(4000);
  return getSummaryResult(summary, threads);
};

export default function useSummary(filters, threads = false) {
  const api = threads ? conversations.getThreadSummary : conversations.getSummary;
  return useQuery({
    queryKey: [threads ? 'threadSummary' : 'summary', { filters }],
    queryFn: async () => {
      const summary = await api(
        filters.topic_id,
        filters.company_id,
        filters.start,
        filters.end,
        transformCustomFieldToPost(filters.custom_fields),
        filters.subtopic_id,
        filters.keywords,
        filters.sentiment,
        filters.labels,
        filters.excluded_keywords,
        filters.sources,
        filters.days,
        filters.exclude_labels,
        filters.nps,
      );
      return getSummaryResult(summary, threads);
    },
  });
}
